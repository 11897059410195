<template>
  <div class="manage-news" :class="{skeleton: !state.loaded}">
    <div class="act">
      <button class="btn btn-bordered-point" @click="$store.commit('openModal', {name: 'CrowdSourcingWrite',
        params: {
          projectSeq: $route.params.projectSeq,
          projectType: $route.params.projectType
        }, callback: 'refresh'})">글쓰기
      </button>
    </div>
    <div class="table-responsive" v-if="state.document.list.length">
      <table class="table table-hover">
        <thead class="thead-light">
        <tr>
          <th>
            <span>자원 성격</span>
          </th>
          <th>
            <span>제목</span>
          </th>
          <th>
            <span>날짜</span>
          </th>
        </tr>
        </thead>
        <tbody class="list">
        <tr class="pointer" v-for="(d, idx) in state.document.list" :key="idx" @click="openModal(d.crowdsourcingSeq)">
          <td class="budget">
            <span>{{ getCrowdSourcingTypeName(d.crowdsourcingType) }}</span>
          </td>
          <td class="budget">
            <span>{{ d.title }}</span>
            <span class="ml-2 text-red" v-if="d.commentsList.length">{{ d.commentsList.length }}</span>
          </td>
          <td class="budget">
            <span>{{ d.updateDate }}</span>
          </td>
        </tr>
        </tbody>
      </table>
    </div>
    <NoData v-else/>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";
import router from "@/scripts/router";
import store from "@/scripts/store";
import NoData from "@/components/NoData";

function Component(initialize) {
  this.name = "pageManageCrowdSourcing";
  this.initialize = initialize;
}

export default defineComponent({
  components: {NoData},
  mixins: [mixin],
  setup() {
    const component = new Component(async () => {
      for (let i = 0; i < 10; i += 1) {
        state.document.list.push({
          title: "Please Wait a moment. Now loading data.",
          createDate: "0000-00-00",
          commentsList: []
        });
      }
      load();
    });

    const state = reactive({
      loaded: false,
      args: {
        pageIndex: 1,
        pageSize: 5,
        pageUnit: 10,
      },
      document: {
        paginationInfo: {},
        list: [],
      }
    });

    const openModal = (seq) => {
      if (!state.loaded) {
        return;
      }

      store.commit("openModal", {
        name: "CrowdSourcing",
        params: {
          crowdsourcingSeq: seq,
          page: "manage",
          projectSeq: router.app.$route.params.projectSeq,
          projectType: router.app.$route.params.projectType,
        },
        callback: "refresh"
      });
    };

    const load = () => {
      state.loaded = false;
      http.get(`/api/maker/project/${router.app.$route.params.projectType}/${router.app.$route.params.projectSeq}/crowd-sourcing`, state.args).then(({data}) => {
        state.loaded = true;
        state.document.paginationInfo = data.body.paginationInfo;
        state.document.list = data.body.list;
      });
    };

    const getCrowdSourcingTypeName = (crowdSourcingType) => {
      if (crowdSourcingType === "people") {
        return "구인";
      } else if (crowdSourcingType === "talent") {
        return "재능기부";
      } else if (crowdSourcingType === "product") {
        return "물품기부";
      } else if (crowdSourcingType === "partner") {
        return "파트너";
      } else {
        return "기타";
      }
    };

    return {component, state, load, openModal, getCrowdSourcingTypeName};
  }
});
</script>

<style lang="scss" scoped>
.manage-news {
  position: relative;

  .act {
    position: absolute;
    top: 0;
    right: 0;
    margin-top: $px-60;
  }

  &.skeleton {
    .act .btn {
      @include skeleton;
    }

    table {
      th, td {
        > span {
          @include skeleton;
        }
      }
    }
  }
}
</style>